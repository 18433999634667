$(document).ready(function() {

    $('.email').nospam({ filterLevel: 'normal',replaceText: true  });
    $('.liame').nospam({ filterLevel: 'normal',replaceText: false  });
    $('.lazy').lazyLoad();

    // $('.openfilter').click(function() {
    //     $('.cardscontent').slideToggle(350);
    //     $(this).toggleClass('open');
    //     return false;
    // });

    /*header search icon*/
    $('.iconsearch').click(function() {
        if ($(this).hasClass('on')) {
            $(this).removeClass('on');
            $('body').removeClass('searchon');
            $('header').removeClass('searchon');
            $('.hdrsearch').slideUp(200);
        } else {
            $(this).addClass('on');
            $('body').addClass('searchon');
            $('header').addClass('searchon');
            $('.hdrsearch').slideDown(200);
            $('input.searchtext').focus();
        }
        $('.openmenu').removeClass('menuon');
        $('body').removeClass('menuopen');
        $('.overlay .submenu').css({'left' : '100%', 'opacity' : '0'});
        $('.sidenavinner').fadeOut(50);
        $('.overlay').delay(200).css({'height' : '0', 'top' : '100%'});
        return false;
    });

    /*mobile search icon*/
    $('.icon-search').click(function() {
        if ($(this).hasClass('on')) {
            $(this).removeClass('on');
            //$('.header').removeClass('searchon');
            $('.hdrsearch').removeClass('searchon').slideUp(200);
        } else {
            $(this).addClass('on');
            //$('.header').addClass('searchon');
            $('.hdrsearch').addClass('searchon').slideDown(200);
        }
        $('.openmenu').removeClass('menuon');
        $('body').removeClass('menuopen');
        $('.overlay .submenu').css({'left' : '100%', 'opacity' : '0'});
        $('.sidenavinner').fadeOut(50);
        $('.overlay').delay(200).css({'height' : '0', 'top' : '100%'});
        return false;
    });

    $('.hdrsearchclose').click(function() {
        $('.iconsearch').removeClass('on');
        $('.icon-search').removeClass('on');
        $('body').removeClass('searchon');
        $('header').removeClass('searchon');
        $('.hdrsearch').slideUp(200);
        return false;
    });

    // $('.cardheader h4').click(function () {
    //     var cardbody = $(this).closest('.cardheader').next('.cardbody');
    //     cardbody.toggleClass('open').slideToggle('fast');
    //     $(this).toggleClass('open');
    //     return false;
    // });

    $('a.carditem label').click(function () {
        $(this).toggleClass('checked');
        var cardattribute = $(this).parent();
        $(cardattribute).attr('aria-checked', function(_, attr) {
            return !(attr == 'true')
        });
        return false;
    });

    $('a.clearfilter').click(function () {
        $('a.carditem').attr('aria-checked', 'false');
        $('a.carditem label').removeClass('checked');
        return false;
    });

    function fixFilter()
    {
        let $filterWrapper = $('.filter-wrapper').first();
        if ($filterWrapper) {
            let filterPosition = $filterWrapper.offset().top;
            if ($(window).scrollTop() > filterPosition) {
                $('.filter-wrapper').addClass('filter-fixed');
            } else {
                $('.filter-wrapper').removeClass('filter-fixed');
            }
        }
    }

    //hide nextevent div on scroll up/down
    var lastScrollTop = 0;
    $(window).scroll(function(){
        var st = $(this).scrollTop();
        var banner = $('.nextevent');
        if (st > lastScrollTop){
            banner.addClass('show');
            if ($(window).width() > 768) {
                $('body.eventon header').css('top','0px');
            }
        } else {
            banner.removeClass('show');;
            if ($(window).width() > 768) {
                $('body.eventon header').css('top','45px');
            }
        }
        lastScrollTop = st;

        fixFilter();
    });

    $('.imgslider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        easing: 'easeInOutElastic'
    });

    $('.teamslider').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        easing: 'easeInOutElastic',
        responsive: [
            {
                breakpoint: 1120,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.teampanelslider').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        easing: 'easeInOutElastic',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.jobslider').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        easing: 'easeInOutElastic',
        responsive: [
            {
                breakpoint: 982,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.resourceslider').slick({
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        easing: 'easeInOutElastic',
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.quoteslider.multi').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        easing: 'easeInOutElastic',
    });

    $('.awardlogos').slick({
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        easing: 'easeInOutElastic',
        variableWidth: true,
        centerMode: true,
    });

    $('.eventslider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        easing: 'easeInOutElastic',
    });

    $('.closebtn').click(function() {
        $('.overlay .submenu').css({'left' : '100%', 'opacity' : '0'});
        $('.openmenu').removeClass('menuon');
        $('body').removeClass('menuopen');
        $('.hdrsearch').slideUp();
        //$('.header').removeClass('searchon');
        $('.sidenavinner').fadeOut(50);
        //$('#sidenav ul.submenu').css({'left' : '100%'});
        $('.overlay').delay(200).css({'height' : '0', 'top' : '100%'});
        return false;
    });
    $('.openmenu').on('click', function(e) {
        e.preventDefault();
        if ($(this).hasClass('menuon')) {
            $(this).removeClass('menuon');
            $('body').removeClass('menuopen');
            $('.overlay .submenu').css({'left' : '100%', 'opacity' : '0'});
            $('.sidenavinner').fadeOut(50);
            $('.overlay').delay(200).css({'height' : '0', 'top' : '100%'});


        } else {
            $(this).addClass('menuon');
            $('body').addClass('menuopen');
            $('.sidenavinner').delay(400).fadeIn();
            if ($(window).width() < 601){
                $('.overlay').css({'height' : '100vh', 'top' : '0'});
            } else {
                $('.overlay').css({'height' : '100vh', 'top' : '0'});
            }
        }
        $('.iconsearch').removeClass('on');
        $('.icon-search').removeClass('on');
        //$('.header').removeClass('searchon');
        $('.hdrsearch').removeClass('searchon').slideUp(200);
        return false;
    });

    /*mobile menu*/
    //$('.mobile-primary > li > .submenu > ul > li:first-child').addClass('toplink');
    //$('.mobile-primary li > .submenu > ul').prepend('<li><a class="navback">Back</a></li>');
    //$('.mobile-primary > li > .submenu > ul > li > .submenu > ul > li:nth-child(2)').addClass('toplink');
    //$('#link_branch_mobile-primary_19 .submenu ul li.toplink').hide();

    $('#sidenav li.expandable > button').on('click', function(e) {
        e.preventDefault();
        $(this).closest('li.expandable').find('.submenu:first').css({'left' : '0', 'width' : '100%', 'opacity' : '1'});
        $(this).closest('li.expandable').find('.submenu ul').delay(400).fadeIn()
        return false;
    });

    $('a.navback').on('click', function(e) {
        e.preventDefault();
        $(this).closest('li.expandable').find('.submenu:first').css({'left' : '100%', 'width' : '0', 'opacity' : '0'});
        $(this).closest('li.expandable').find('.submenu ul').fadeOut(50);
        return false;
    });

    //$('.sidenavinner').each(function(){ const ps = new PerfectScrollbar($(this)[0]); });

    $('.imgslider .item').each(function() {
        // $(this).find('.itemtxt').equalHeights()
    })

    $(document).on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();
        if ($(window).width() > 768) {
            var topOffset = 100;
        } else {
            var topOffset = 20;
        }
        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top - topOffset
        }, 500);
    });

    $(".heroapply").sticky({topSpacing:0});
    // if ($(window).width() < 769) {
    //     $(".filters").sticky({topSpacing: 0});
    // }

    // $('.file').on('change', function() {
    //     var files = $(this)[0].files;
    //     for(var i = 0; i<files.length; i++){
    //         //console.log(files[i].name+'----'+files[i].size);
    //         if (fileSize > 1024) {
    //             fileSize = (fileSize / 1024).toFixed(2) + 'MB';
    //         } else {
    //             fileSize = fileSize + 'KB';
    //         }
    //         $(this).closest('.fileinput').find('p.filename').text('Selected file: ' + files[i].name + ' ' + fileSize)
    //     }
    // });

    $('.mpvideo').magnificPopup({
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
    });

});

$.fn.extend({
    lazyLoad : function() {
        $(this).each( function() {
            if( inViewPort( $(this) ) ) {
                $(this).addClass('in');
            } else {
                $(this).removeClass('in');
            }
        });
    }
});

$.fn.equalHeights = function(){
    let maxHeight = 0;
    $(this).each(function(){
        maxHeight = Math.max($(this).height(), maxHeight);
    });
    $(this).each(function(){
        $(this).height(maxHeight);
    });
};

function inViewPort(e) {
    // cache viewport height and object offset
    var windowBottom = $(window).scrollTop() + $(window).height(),
        objectBottom = e.offset().top + e.outerHeight() / 3,
        inView = false;

    // if the object is completely visible in the window
    if ( windowBottom >= objectBottom ){
        inView = true;
    }
    return inView;
}
