/*
 * Accept cookies popup
 */
$(document).on('click', '.accept_cookies', function() {

    axios.get('/manager/accept-cookies')
        .then(function (response) {
            console.log(response);
            $('.cookie-banner').fadeOut();
        })
        .catch(function(error) {
            console.log(error);
        });

});
